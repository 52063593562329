import img2 from "./img/S__6340610.jpg";
import img3 from "./img/S__6340612.jpg";
import img4 from "./img/S__6340613.jpg";
import img5 from "./img/S__6340614.jpg";
import img6 from "./img/S__6340615.jpg";
import img7 from "./img/S__6340616.jpg";
import img8 from "./img/S__6340617.jpg";
import img9 from "./img/S__6340618.jpg";
import img10 from "./img/S__6340619.jpg";
import img11 from "./img/S__6340620.jpg";
import img12 from "./img/S__6340623.jpg";
import img13 from "./img/S__6340624.jpg";
import img14 from "./img/S__6340625.jpg";
import img15 from "./img/S__6340626.jpg";
import img16 from "./img/S__6340627.jpg";
import img17 from "./img/S__6340628.jpg";
import img18 from "./img/S__6340629.jpg";
import img19 from "./img/S__6340630.jpg";
import img20 from "./img/S__6340631.jpg";
import img21 from "./img/S__6340632.jpg";
import img23 from "./img/S__6340634.jpg";
import img24 from "./img/S__6340635.jpg";
import img26 from "./img/S__6340636.jpg";
import img27 from "./img/S__6340638.jpg";
import img28 from "./img/S__6340639.jpg";
import img29 from "./img/S__6340640.jpg";
import img30 from "./img/S__6340641.jpg";
import img31 from "./img/S__6340642.jpg";
import img32 from "./img/S__6340643.jpg";
import img33 from "./img/S__6340645.jpg";
import img34 from "./img/S__6340646.jpg";
import img35 from "./img/S__6340647.jpg";
import img36 from "./img/S__6340648.jpg";
import img37 from "./img/S__6340649.jpg";
import img38 from "./img/S__6340650.jpg";
import img39 from "./img/S__6340651.jpg";

const imgList = [
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img23,
  img24,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
];

export default imgList;
